<script setup>
 const { anchorId, headline, tag, icons, pullQuote } = defineProps({
  anchorId: {
   type: String,
   required: false,
   default: '',
  },
  headline: {
   type: String,
   required: true,
  },
  tag: {
   type: String,
   required: true,
  },
  icons: {
   type: Array,
   required: true,
  },
  pullQuote: {
   type: Object,
   required: true,
  },
  attributes: {
   type: Object,
   default: () => ({}),
  },
 });
</script>

<template>
 <section :id="anchorId" class="block block-icon-grid bg-red">
  <div class="container grid" v-aos>
   <FragmentsCardBox class="first" :headline="{ html: headline, tag: 'h3', class: 'h3' }" padding="large" />
   <FragmentsCardBox
    padding="small"
    background="dark-gray"
    class="second"
    icon="c-small-bug"
    :copy="pullQuote.copy"
    :button="pullQuote.button"
   />
   <MessHtml v-if="tag" :html="tag" class="utility tag text-white" />
   <div class="icon-group">
    <ProjectIconGroup v-for="icon in icons" :icon="icon.icon" :copy="icon.copy" />
   </div>
  </div>
 </section>
</template>

<style lang="scss">
 .block-icon-grid {
  .grid {
   position: relative;
   .first {
    margin-top: -17px;
    grid-column: 1 / 4;
    @media (max-width: 480px) {
     padding: 24px;
     width: calc(100% + var(--gap) + 24px);
    }
    @media (max-width: 767px) {
     .h3 {
      font-size: 36px;
     }
    }

    .h3 {
     margin-bottom: 0;
    }
   }
   .second {
    grid-column: 2 / 5;
    grid-row: 2;
    transition-delay: 250ms;
   }
   .icon-group {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 2rem;
    grid-column: 1 / 5;
    justify-content: center;
    margin: 4rem 0 0 0;
    .cac-icon {
     margin-bottom: 20px;
    }
   }
   @media (min-width: $mobile) {
    .first {
     grid-column: 1 / 9;
     width: 391px;
    }
    .second {
     grid-column: 2 / 9;
    }
    .icon-group {
     grid-column: 1 / span 12;
    }
   }
   @media (min-width: $tablet) {
    .first {
     grid-column: 1 / 5;
    }
    .second {
     grid-column: 2 / 6;
    }
    .icon-group {
     grid-column: 7 / 13;
     grid-row: 1 / span 2;
     margin: 0;
    }
   }
  }
  .tag {
   position: absolute;
   transform: rotate(270deg);
   right: 0;
   top: 12px;
   color: var(--white);
   width: min-content;
   height: min-content;
   @media (min-width: $tablet) {
    top: calc(100% - 36px);
    left: -27px;
   }
  }
  .v-aos-before-enter {
   .second {
    clip-path: inset(0 100% 100% 0);
    opacity: 0;
   }
   .first {
    clip-path: inset(100% 0 0 100%);
    opacity: 0;
   }
  }
  .v-aos-entered {
   .second {
    clip-path: inset(0 0 0 0);
    opacity: 1;
   }
   .first {
    clip-path: inset(0 0 0 0);
    opacity: 1;
   }
  }
 }
</style>
